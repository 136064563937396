var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"d-flex pb-4"},[_c('div',{staticClass:"ticketTextHeading"},[_vm._v("Tickets sold information")]),_c('div',{staticClass:"ticketTextLine"}),_vm._v("    "),(this.allseats)?_c('h4',{staticClass:"ticketTextHeading1"},[_vm._v(" ("+_vm._s(this.totalticketssold)+"/"+_vm._s(this.allseats)+") ")]):_c('h4',{staticClass:"ticketTextHeading1"},[_vm._v(" ("+_vm._s(this.totalticketssold)+"/0) ")])]),_c('div',[_c('h5',[_vm._v("Ticket URL: "),_c('a',{staticStyle:{"margin-top":"1%"},attrs:{"href":_vm.pretixRedirectUrl,"target":"_blank"}},[_vm._v(_vm._s(this.pretixRedirectUrl))])])]),_c('div',[_c('h5',[_vm._v("Facebook Event URL: "),_c('a',{staticStyle:{"margin-top":"1%"},attrs:{"href":_vm.facebookUrl,"target":"_blank"}},[_vm._v(_vm._s(this.facebookUrl))])])]),(this.boostedonFlag)?_c('div',[_c('h5',[_vm._v("Boosted On Facebook: "),(this.boosted_on_fb)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("YES")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v("NO")])])]):_vm._e(),_c('div',[_c('h5',[_vm._v("Custom Video URL: "),_c('a',{staticStyle:{"margin-top":"1%"},attrs:{"href":_vm.videoUrl,"target":"_blank"}},[_vm._v(_vm._s(this.videoUrl))])])]),_c('br'),(
        (this.adminAccess === 'Super Admin' ||
          this.adminAccess == 'Sales Representative' ||
          this.adminAccess == 'Event Support Staff') &&
        _vm.$vuetify.breakpoint.smAndUp
      )?_c('v-btn',{staticClass:"text-capitalize px-5",staticStyle:{"border-radius":"10px"},attrs:{"dark":"","color":"#2C1963","height":"44"},on:{"click":function($event){return _vm.toggleTicketInfoModal({ show: true })}}},[_c('span',[_vm._v("Update Info")])]):_vm._e(),(
        (this.adminAccess === 'Super Admin' ||
          this.adminAccess == 'Sales Representative' ||
          this.adminAccess == 'Event Support Staff') &&
        _vm.$vuetify.breakpoint.xsOnly
      )?_c('v-btn',{staticClass:"text-capitalize px-5",staticStyle:{"border-radius":"10px"},attrs:{"dark":"","color":"#2C1963","height":"44"},on:{"click":function($event){return _vm.toggleTicketInfoModal({ show: true })}}},[_c('span',[_vm._v("Update Info")])]):_vm._e()],1),_c('v-simple-table',{staticClass:"elevation-8",staticStyle:{"margin-top":"2%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticStyle:{"background":"#e7e5ed"}},[_c('th',{staticStyle:{"width":"80px"}}),_vm._l((_vm.filteredItems),function(item,i){return _c('th',{key:i,staticClass:"textHeader text-center"},[_vm._v(" "+_vm._s(item.ticket_type_name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80px"}},[_c('div',[_vm._v("Online")])]),_vm._l((_vm.filteredItems),function(item,i){return _c('td',{key:i,staticClass:"text-center"},[_vm._v(" "+_vm._s(item.total_online_ticket_sold)+" ")])})],2),_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"80px"}},[_c('div',[_vm._v("Paper")])]),_vm._l((_vm.filteredItems),function(item,i){return _c('td',{key:i,staticClass:"text-center"},[_vm._v(" "+_vm._s(item.total_offline_ticket_sold)+" ")])})],2)])]},proxy:true}])}),_c('div',{staticClass:"d-flex pt-3 px-4"},[(_vm.profit)?_c('v-icon',{attrs:{"small":"","color":"#067605"}},[_vm._v("mdi-arrow-up")]):_c('v-icon',{attrs:{"small":"","color":"#D21140"}},[_vm._v(" mdi-arrow-down")]),_c('div',{staticClass:"footerStyle",style:(_vm.profit ? 'color:#067605' : 'color:#D21140')},[_vm._v(" "+_vm._s(_vm.profit_amount)+"%(You have sold "+_vm._s(_vm.profit_amount)+"% more tickets in comparison to last year) ")]),_c('v-spacer'),_c('div',{staticClass:"footerStyle",staticStyle:{"font-size":"12px"}},[_vm._v(" Last update by "+_vm._s(_vm.last_updateBy)+" "+_vm._s(_vm.last_updatedDate)+" at "+_vm._s(_vm.last_updatedTime)+" ")])],1),_c('br'),_c('ticket-info-modal',{on:{"reload":_vm.getTicketInfo}}),_c('br'),_c('br'),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }